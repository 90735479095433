.SidebarOption {
  display: flex;
  align-items: center;
  color: grey;
  height: 40px;
  cursor: pointer;
  transition: 200ms color ease-in;
}

.sidebar > hr {
  border: 1px solid #282828;
  width: 90%;
  margin: 10px auto;
}
.SidebarOption > p {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 14px;
}

.SidebarOption:hover {
  color: white;
}

.SidebarOption__Icon {
  padding-left: 10px;
  padding-right: 10px;
}

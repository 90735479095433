.sidebar {
  height: 100vh;
  flex: 0.2;
  color: white;
  min-width: 230px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #040404;
}

.sidebar__logo {
  margin-right: auto;
  padding: 10px;
  height: 70px;
}

.sidebar__title {
  margin-left: 10px;
  padding: 5px;
  font-size: 12px;
}

@media (max-width: 550px) {
  .sidebar {
    min-width: 140px;
  }

  .sidebar__logo {
    margin-right: auto;
    padding: 10px;
    height: 50px;
  }

  .sidebar__title {
    margin-left: 10px;
    padding: 5px;
    font-size: 11px;
  }
}
